//  React Imports
import React from "react";

/**
 *  Redux Imports
 */
import { toggleNavCollapsed } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";

/**
 *  Material-UI Imports
 */
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

/**
 *  Node Module Imports
 */
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";

/**
 *   Component Imports
 */
import UserInfo from "shared/components/UserInfo";
import Navigation from "../../Navigation/VerticleNav";
import useStyles from "./AppSidebar.style";

/**
 *  Main Component
 */
const AppSidebar = (props) => {
  //  Store Selectors
  const navCollapsed = useSelector(({ settings }) => settings.navCollapsed);

  //  State Intializers
  const dispatch = useDispatch();
  const classes = useStyles(props);

  const handleToggleDrawer = () => {
    dispatch(toggleNavCollapsed());
  };

  let sidebarClasses = classes.sidebarStandard;

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor={props.position}
          open={navCollapsed}
          onClose={(ev) => handleToggleDrawer()}
          classes={{
            root: clsx(classes.sidebarWrapper, props.variant),
            paper: clsx(
              classes.sidebar,
              props.variant,
              props.position === "left"
                ? classes.leftSidebar
                : classes.rightSidebar
            ),
          }}
          // container={props.rootRef.current}
          BackdropProps={{
            classes: {
              root: classes.backdrop,
            },
          }}
          style={{ position: "absolute" }}
        >
          <Box height="100%" className={classes.container}>
            <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
              <UserInfo />
              <PerfectScrollbar className={classes.drawerScrollAppSidebar}>
                <Navigation />
              </PerfectScrollbar>
            </Box>
          </Box>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Box height="100%" className={clsx(classes.container, "app-sidebar")}>
          <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
            <UserInfo />
            <PerfectScrollbar className={classes.scrollAppSidebar}>
              <Navigation />
            </PerfectScrollbar>
          </Box>
        </Box>
      </Hidden>
    </>
  );
};

export default AppSidebar;

AppSidebar.defaultProps = {
  variant: "",
  position: "left",
};

AppSidebar.propTypes = {
  position: PropTypes.string,
  variant: PropTypes.string,
};
