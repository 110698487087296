import React from "react";

export const dashboardRouteConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    messageId: "sidebar.app.dashboard",
    type: "item",
    url: "/dashboard/analytics",
    icon: "dashboard",
  },
];

export const dashboardConfig = [
  {
    auth: ["user"],
    routes: [
      {
        path: "/dashboard/analytics",
        component: React.lazy(() => import("./Analytics")),
      },
    ],
  },
];
