//  Action Imports
import { fetchError, fetchStart, fetchSuccess } from "../Common";

/**
 *  Type Imports
 */
import {
  GET_NOTIFICATIONS_DATA,
  VIEW_NOTIFICATION_FINISH,
  VIEW_NOTIFICATION_START,
} from "shared/constants/ActionTypes";

/**
 *  Service Imports
 */
import ApiService from "@crema/services/ApiService";
import { notificationApi } from "shared/constants/AppConst";

/**
 * Notification Actions
 */
export const onGetNotificationsData = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(`${notificationApi.getAll}`);
      console.log(data);

      // const { meta, items } = data;

      const result = {
        data: data.data,
        viewing: false,
        selectedNotification: null,
        pagination: {
          start: 0,
          limit: 10,
          page: 0,
          count: data.total, //total
        },
      };

      dispatch({
        type: GET_NOTIFICATIONS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onViewNotificationStart = (notification) => ({
  type: VIEW_NOTIFICATION_START,
  payload: notification,
});

export const onViewNotificationFinish = () => ({
  type: VIEW_NOTIFICATION_FINISH,
});

export const setNotificationCurrentTablePage = (page, limit) => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());

      const start = page * limit;

      let data = await ApiService.get(`${notificationApi.get(start, limit)}`);

      const result = {
        data: data.data,
        viewing: false,
        selectedNotification: null,
        pagination: {
          start,
          page,
          limit,
          count: data.total, //total
        },
      };

      dispatch({
        type: GET_NOTIFICATIONS_DATA,
        payload: result,
      });
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const setNotificationRowsPerPage = (limit) => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(notificationApi.get(0, limit));
      console.log(data);

      // const { meta, items } = data;

      const result = {
        data: data.data,
        pagination: {
          start: 0,
          page: 0,
          limit: limit,
          count: data.total, //total
        },
        viewing: false,
        selectedNotification: null,
      };

      dispatch({
        type: GET_NOTIFICATIONS_DATA,
        payload: result,
      });
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
