import Api from "./ApiConfig";

class ApiService {
  constructor() {
    this.api = Api;
  }

  get = async (
    url
    // authHeader = {}
  ) => {
    try {
      const response = await this.api.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          // ...authHeader,
        },
      });
      if (response && response.status === 200 && response.data) {
        return response.data;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

  post = async (url, data) => {
    try {
      const response = await this.api.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (
        response &&
        response.data &&
        (response.status === 200 || response.status === 201)
      ) {
        return response.data;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

  put = async (url, data = {}) => {
    try {
      const response = await this.api.put(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response && response.data) {
        return response.data;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

  delete = async (url) => {
    try {
      const response = await this.api.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response && response.data) {
        return response.data;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
}

export default new ApiService();
