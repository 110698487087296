import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Setting";
import Common from "./Common";
import Auth from "./Auth";
import Management from "./Management";
import Report from "./Report";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    management: Management,
    report: Report,
  });
