// React Imports
import React from "react";

/**
 *  Redux Imports
 */
import { useSelector } from "react-redux";

/**
 *  Component Imports
 */
import { Loader, MessageView } from "@crema";

/**
 *  Main Component
 */
const InfoView = () => {
  // DataStore
  const { error, loading, message } = useSelector(({ common }) => common);

  const showMessage = () => {
    return <MessageView variant="success" message={message.toString()} />;
  };

  const showError = () => {
    return <MessageView variant="error" message={error.toString()} />;
  };

  return (
    <>
      {loading && <Loader />}
      {message && showMessage()}
      {error && showError()}
    </>
  );
};

export default InfoView;
