import {
  GET_ADMIN_MANAGEMENT_DATA,
  EDIT_ADMIN_INFORMATION_START,
  EDIT_ADMIN_INFORMATION_FINISH,
  //   SET_TABLE_PAGE, ADMIN
  //   SET_ROWS_PER_PAGE,ADMIN
  OPEN_ADMIN_DELETE_DIALOG,
  CLOSE_ADMIN_DELETE_DIALOG,
  ADD_ADMIN_MANAGEMENT_DATA,
  GET_USER_DATA,
  OPEN_USER_NOTIFICATON_DIALOG,
  EDIT_USER_INFORMATION_FINISH,
  CLOSE_USER_DELETE_DIALOG,
  OPEN_USER_DELETE_DIALOG,
  EDIT_USER_INFORMATION_START,
  CLOSE_USER_NOTIFICATON_DIALOG,
  GET_NOTIFICATIONS_DATA,
  VIEW_NOTIFICATION_FINISH,
  VIEW_NOTIFICATION_START,
  GET_BULK_NOTIFICATION_DATA,
  CHECK_USER,
  UNCHECK_USER,
  GET_TEXT_ALERTS_DATA,
  GET_STATIC_MSGS,
  VIEW_TEXT_ALERT_START,
  VIEW_TEXT_ALERT_FINISH,
} from "shared/constants/ActionTypes";

const initialState = {
  adminManagementData: {
    data: [],
    editing: false,
    pagination: {
      page: 0,
      rowsPerPage: 5,
      count: 0,
      lastPageFetched: 2,
    },
    editingUser: null,
    deleting: false,
    deletingUser: null,
  },
  userData: {
    data: [],
    editing: false,
    pagination: {
      start: 0,
      limit: 10,
      page: 0,
    },
    editingUser: null,
    deleting: false,
    deletingUser: null,
    sending: false,
    selectedPatient: null,
  },

  notificationData: {
    data: [],
    viewing: false,
    pagination: {
      start: 0,
      limit: 10,
      page: 0,
    },
    selectedNotification: null,
  },
  // wip
  bulkNotificationData: {
    data: [],
    isAllChecked: false, //?
    userIds: [],
    filters: {
      device: "",
      gender: "",
      appVersion: "",
      isActive: "",
      isVerified: "",
      mobileNumber: "",
      email: "",
      NID: "",
      firstname: "",
      lastname: "",
      dateOfBirth: "",
    },

    pagination: {
      page: 1,
      itemCount: 5,
      itemsPerPage: 10,
      count: 0,
      totalPages: 0,
    },
  },

  //  wip
  textAlertData: {
    data: [],
    viewing: false,
    staticMsgs: {},
    pagination: {
      page: 1,
      itemCount: 5,
      itemsPerPage: 10,
      count: 0,
      totalPages: 0,
    },
    selectTextAlert: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_MANAGEMENT_DATA:
      return {
        ...state,
        adminManagementData: action.payload,
      };

    case ADD_ADMIN_MANAGEMENT_DATA:
      return {
        ...state,
        adminManagementData: {
          ...state.adminManagementData,
          data: [...state.adminManagementData.data, ...action.payload],
        },
      };

    case EDIT_ADMIN_INFORMATION_START:
      if (state.adminManagementData)
        return {
          ...state,
          adminManagementData: {
            ...state.adminManagementData,
            editing: true,
            editingUser: action.payload,
          },
        };
      else return state;
    case EDIT_ADMIN_INFORMATION_FINISH:
      if (state.adminManagementData)
        return {
          ...state,
          adminManagementData: {
            ...state.adminManagementData,
            editing: false,
            editingUser: null,
          },
        };
      else return state;
    case OPEN_ADMIN_DELETE_DIALOG:
      if (state.adminManagementData) {
        return {
          ...state,
          adminManagementData: {
            ...state.adminManagementData,
            deleting: true,
            deletingUser: action.payload,
          },
        };
      } else return state;
    case CLOSE_ADMIN_DELETE_DIALOG:
      if (state.adminManagementData) {
        return {
          ...state,
          adminManagementData: {
            ...state.adminManagementData,
            deleting: false,
            deletingUser: null,
          },
        };
      } else return state;

    case GET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };

    case OPEN_USER_NOTIFICATON_DIALOG:
      if (state.userData)
        return {
          ...state,
          userData: {
            ...state.userData,
            sending: true,
            selectedPatient: action.payload,
          },
        };
      else return state;

    case CLOSE_USER_NOTIFICATON_DIALOG:
      if (state.userData)
        return {
          ...state,
          userData: {
            ...state.userData,
            sending: false,
            selectedPatient: null,
          },
        };
      else return state;

    case EDIT_USER_INFORMATION_START:
      if (state.userData)
        return {
          ...state,
          userData: {
            ...state.userData,
            editing: true,
            editingUser: action.payload,
          },
        };
      else return state;

    case EDIT_USER_INFORMATION_FINISH:
      if (state.userData)
        return {
          ...state,
          userData: {
            ...state.userData,
            editing: false,
            editingUser: null,
          },
        };
      else return state;
    case OPEN_USER_DELETE_DIALOG:
      if (state.userData) {
        return {
          ...state,
          userData: {
            ...state.userData,
            deleting: true,
            deletingUser: action.payload,
          },
        };
      } else return state;
    case CLOSE_USER_DELETE_DIALOG:
      if (state.userData) {
        return {
          ...state,
          userData: {
            ...state.userData,
            deleting: false,
            deletingUser: null,
          },
        };
      } else return state;

    case GET_NOTIFICATIONS_DATA:
      return {
        ...state,
        notificationData: action.payload,
      };

    case VIEW_NOTIFICATION_START:
      if (state.notificationData) {
        return {
          ...state,
          notificationData: {
            ...state.notificationData,
            viewing: true,
            selectedNotification: action.payload,
          },
        };
      } else return state;

    case VIEW_NOTIFICATION_FINISH:
      if (state.notificationData)
        return {
          ...state,
          notificationData: {
            ...state.notificationData,
            viewing: false,
            selectedNotification: null,
          },
        };
      else return state;

    //
    case GET_BULK_NOTIFICATION_DATA:
      return {
        ...state,
        bulkNotificationData: action.payload,
      };

    case CHECK_USER: {
      return {
        ...state,
        bulkNotificationData: {
          ...state.bulkNotificationData,
          userIds: [...state.bulkNotificationData.userIds, action.payload],
        },
      };
    }

    case UNCHECK_USER: {
      return {
        ...state,
        bulkNotificationData: {
          ...state.bulkNotificationData,
          userIds: state.bulkNotificationData.userIds.filter(
            (id) => id !== action.payload
          ),
        },
      };
    }

    // wip

    case GET_TEXT_ALERTS_DATA:
      return {
        ...state,
        textAlertData: action.payload,
      };

    case GET_STATIC_MSGS: {
      return {
        ...state,
        textAlertData: {
          ...state.textAlertData,
          staticMsgs: action.payload,
        },
      };
    }

    case VIEW_TEXT_ALERT_START:
      if (state.textAlertData) {
        return {
          ...state,
          textAlertData: {
            ...state.textAlertData,
            viewing: true,
            selectedAlertData: action.payload,
          },
        };
      } else return state;

    case VIEW_TEXT_ALERT_FINISH:
      if (state.textAlertData)
        return {
          ...state,
          textAlertData: {
            ...state.textAlertData,
            viewing: false,
            selectedAlertData: null,
          },
        };
      else return state;

    default:
      return state;
  }
};
