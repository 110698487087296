//  React Imports
import React from "react";

/**
 *  Node Module Imports
 */
import { Provider } from "react-redux";
import { history, store } from "./redux/store";
import { ConnectedRouter } from "connected-react-router";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "date-fns";

// import DateFnsUtils from "@date-io/date-fns";

/**
 *  Component Imports
 */
import AuthRoutes from "@crema/utility/AuthRoutes";
import LocaleProvider from "@crema/utility/LocaleProvider";
import AppLayout from "@crema/core/AppLayout";
import CremaThemeProvider from "@crema/utility/CremaThemeProvider";
import CremaStyleProvider from "@crema/utility/CremaStyleProvider";
import ContextProvider from "@crema/utility/ContextProvider";

/**
 *  Style Imports
 */
import "react-phone-input-2/lib/material.css";
// import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";

const App = () => (
  <ContextProvider>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <CremaThemeProvider>
          <CremaStyleProvider>
            <LocaleProvider>
              <ConnectedRouter history={history}>
                <AuthRoutes>
                  <CssBaseline />
                  <AppLayout />
                </AuthRoutes>
              </ConnectedRouter>
            </LocaleProvider>
          </CremaStyleProvider>
        </CremaThemeProvider>
      </Provider>
    </MuiPickersUtilsProvider>
  </ContextProvider>
);

export default App;
