//  React Imports
import React, { useContext, useState } from "react";

/**
 *  Material-UI Imports
 */
import { makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import { grey, orange } from "@material-ui/core/colors";

/**
 *  Redux Imports
 */
import { useDispatch } from "react-redux";
import { onJWTAuthSignout } from "redux/actions";

/**
 *  Utility Imports
 */
import { useAuthUser } from "@crema/utility/AppHooks";
import AppContext from "@crema/utility/AppContext";

/**
 *  Node Module Imports
 */
import clsx from "clsx";
import { Fonts } from "shared/constants/AppEnums";
import IntlMessages from "@crema/utility/IntlMessages";

/**
 *  Cropper Configuration
 */
const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

/**
 *  UseStyles
 */
const useStyles = makeStyles((theme) => ({
  crUserInfo: {
    backgroundColor: "rgba(0,0,0,.08)",
    paddingTop: 9,
    paddingBottom: 9,
    minHeight: 56,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 10,
      paddingBottom: 10,
      minHeight: 70,
    },
  },
  profilePic: {
    height: 40,
    width: 40,
    fontSize: 24,
    backgroundColor: orange[500],
    [theme.breakpoints.up("xl")]: {
      height: 45,
      width: 45,
    },
  },
  userInfo: {
    width: "calc(100% - 75px)",
  },
  userName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 18,
    fontFamily: Fonts.MEDIUM,
    [theme.breakpoints.up("xl")]: {
      fontSize: 20,
    },
    color: (props) => (props.themeMode === "light" ? "#313541" : "white"),
  },
  designation: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  pointer: {
    cursor: "pointer",
  },
  adminRoot: {
    color: grey[500],
    fontSize: 16,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const UserInfo = (props) => {
  //  State Initializers
  const { themeMode } = useContext(AppContext);
  const dispatch = useDispatch();
  const user = useAuthUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const onAvatarClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles({ themeMode });

  return (
    <Box
      px={{ xs: 4, xl: 7 }}
      className={clsx(classes.crUserInfo, "cr-user-info")}
    >
      <Box display="flex" alignItems="center" onClick={onAvatarClick}>
        <Box ml={4} className={clsx(classes.userInfo, "user-info")}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box mb={0} className={clsx(classes.userName)}>
              {user.displayName ? (
                user.displayName
              ) : (
                <IntlMessages id="dashboard.adminUser" />
              )}
            </Box>
            <Box
              ml={3}
              className={classes.pointer}
              color={themeMode === "light" ? "#313541" : "white"}
            >
              <ExpandMoreIcon onClick={handleClick} />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    if (user) {
                      dispatch(onJWTAuthSignout());
                    }
                  }}
                >
                  <IntlMessages id="dashboard.logout" />
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserInfo;
