/**
 *  Type Imports
 */
import {
  SET_TOKEN_SET,
  SIGNOUT_USER_SUCCESS,
  UPDATE_AUTH_USER,
} from "shared/constants/ActionTypes";

/**
 *  Other Imports
 */
import ApiService from "@crema/services/ApiService";
import { adminApi, authApi } from "shared/constants/AppConst";
import { fetchError, fetchStart, fetchSuccess, showMessage } from "./Common";
import { history } from "redux/store";
import IntlMessages from "@crema/utility/IntlMessages";

export const onJwtUserSignUp = ({ email, password, name }) => {
  console.log("email, password", { email, password, name });
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = { email, name, password };
    try {
      const res = await ApiService.post("users", body);
      localStorage.setItem("token", res.data.token);

      dispatch(setJWTToken(res.data.token));
    } catch (err) {
      console.log("error!!!!", err);
      if (err.response) dispatch(fetchError(err.response.data.error));
      else dispatch(fetchError(err.message));
    }
  };
};

export const onJwtSignIn = ({ email, password }) => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());
      const body = { email, password };
      const res = await ApiService.post(authApi.adminLogin, body);
      dispatch(setJWTToken(res.token));

      const data = await ApiService.get(adminApi.getPermissions(res.data.id));
      let userInfo = { ...res.data, ...data, id: res.data.id };
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      dispatch(updateUser(userInfo));
      dispatch(fetchSuccess());
    } catch (err) {
      console.log("error!!!!", err);
      if (err.response) dispatch(fetchError(err.response.data.error));
      else dispatch(fetchError(err.message));
    }
  };
};

export const setJWTToken = (token) => {
  if (!token) {
    // alert(token);
    localStorage.removeItem("token");
  }
  return async (dispatch) => {
    dispatch({
      type: SET_TOKEN_SET,
      payload: token,
    });
  };
};

export const updateUser = (user) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const createUser = (user) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      const res = await ApiService.post(authApi.userSignup, user);

      console.log(res);
      dispatch(fetchSuccess());
      dispatch(showMessage(<IntlMessages id="message.userCreated" />));
    } catch (err) {
      console.log("error!!!!", err);
      if (err.response) dispatch(fetchError(err.response.data.error));
      else dispatch(fetchError(err.message));
    }
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    history.push("/");

    setTimeout(() => {
      dispatch({ type: SIGNOUT_USER_SUCCESS });
      dispatch(fetchSuccess());
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
    }, 500);
  };
};

export const onJWTForgotPassword = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch({ type: SIGNOUT_USER_SUCCESS });
      dispatch(fetchSuccess());
      localStorage.removeItem("auth-user");
    }, 500);
  };
};
