//  Action Imports
import { fetchError, fetchStart, fetchSuccess } from "../Common";

/**
 *  Type Imports
 */
import {
  GET_REPORTS_DATA,
  VIEW_REPORT_FINISH,
  VIEW_REPORT_START,
} from "shared/constants/ActionTypes";

/**
 *  Service Imports
 */
import ApiService from "@crema/services/ApiService";
import { reportApi } from "shared/constants/AppConst";

/**
 * Report-demo Actions
 */
export const onGetReportsData = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(reportApi.getAll);
      console.log(data);

      // const { meta, items } = data;

      const result = {
        data: data.data,
        pagination: {
          start: 0,
          limit: 10,
          page: 0,
          count: data.total, //total
        },
      };

      dispatch({
        type: GET_REPORTS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onViewReportStart = (report) => ({
  type: VIEW_REPORT_START,
  payload: report,
});

export const onViewReportFinish = () => ({
  type: VIEW_REPORT_FINISH,
});

export const setReportCurrentTablePage = (page, limit) => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());

      const start = page * limit;

      let data = await ApiService.get(reportApi.get(start, limit));

      const result = {
        data: data.data,
        pagination: {
          start,
          page,
          limit,
          count: data.total, //total
        },
      };

      dispatch({
        type: GET_REPORTS_DATA,
        payload: result,
      });
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const setReportRowsPerPage = (limit) => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(reportApi.get(0, limit));
      // const { meta, items } = data;

      const result = {
        data: data.data,
        pagination: {
          start: 0,
          page: 0,
          limit: limit,
          count: data.total, //total
        },
      };

      dispatch({
        type: GET_REPORTS_DATA,
        payload: result,
      });
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
