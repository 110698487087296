//  Action Imports
import { fetchError, fetchStart, fetchSuccess } from "../Common";

/**
 *  Type Imports
 */
import {
  GET_USER_DATA,
  OPEN_USER_NOTIFICATON_DIALOG,
  EDIT_USER_INFORMATION_FINISH,
  CLOSE_USER_DELETE_DIALOG,
  OPEN_USER_DELETE_DIALOG,
  EDIT_USER_INFORMATION_START,
  CLOSE_USER_NOTIFICATON_DIALOG,
} from "shared/constants/ActionTypes";

/**
 *  Service Imports
 */
import ApiService from "@crema/services/ApiService";
import { userApi } from "shared/constants/AppConst";

/**
 * User Actions
 */
export const onGetUsersManagementData = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(`${userApi.getAll}`);
      console.log(data);

      // const { meta, items } = data;

      const result = {
        data: data.data,
        editing: false,
        pagination: {
          start: 0,
          limit: 10,
          page: 0,
          count: data.total, //total
        },
        editingUser: null,
        deleting: false,
        deletingUser: null,
        sending: false,
        selectedUser: null,
      };

      dispatch({
        type: GET_USER_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const openUserNotificationDialog = (user) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OPEN_USER_NOTIFICATON_DIALOG,
        payload: user,
      });
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const closeUserNotificationDialog = () => ({
  type: CLOSE_USER_NOTIFICATON_DIALOG,
});

export const onEditUserInformationStart = (user) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: EDIT_USER_INFORMATION_START,
        payload: user,
      });
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onEditUserInformationFinish = () => {
  return (dispatch) => {
    dispatch({
      type: EDIT_USER_INFORMATION_FINISH,
    });
  };
};

export const openUserDeleteDialog = (payload) => {
  return (dispatch) => {
    dispatch({
      type: OPEN_USER_DELETE_DIALOG,
      payload,
    });
  };
};

export const closeUserDeleteDialog = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_USER_DELETE_DIALOG,
    });
  };
};

export const setUserCurrentTablePage = (page, limit) => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());

      console.log(page, limit);

      // either page or page + 1

      let start = page * limit;
      let data = await ApiService.get(`${userApi.get(start, limit)}`);
      // console.log(data,data.data,data.total);
      console.log(data, "this is it");

      // return;
      // const { meta, items } = data;

      const result = {
        data: data.data,
        editing: false,
        pagination: {
          start,
          page,
          limit,
          count: data.total, //total
        },
        editingUser: null,
        deleting: false,
        deletingUser: null,
        sending: false,
        selectedUser: null,
      };

      dispatch({
        type: GET_USER_DATA,
        payload: result,
      });
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const setUserRowsPerPage = (limit) => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(`${userApi.get(0, limit)}`);
      console.log(data);

      const result = {
        data: data.data,
        editing: false,
        pagination: {
          start: 0,
          page: 0,
          limit: limit,
          count: data.total, //total
        },
        editingUser: null,
        deleting: false,
        deletingUser: null,
        sending: false,
        selectedUser: null,
      };

      dispatch({
        type: GET_USER_DATA,
        payload: result,
      });
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
