const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  },
  {
    languageId: "saudi-arabia",
    locale: "ar",
    name: "العربية",
    icon: "sa",
  },
];
export default languageData;
