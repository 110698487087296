//  React Imports
import React from "react";

/**
 *  Material-UI Imports
 */
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

/**
 *  Component Imports
 */
import LanguageSwitcher from "../../LanguageSwitcher";
import AppLogo from "shared/components/AppLogo";
import useStyles from "./AppHeader.style";

/**
 *  Redux Imports
 */
import { toggleNavCollapsed } from "redux/actions";
import { useDispatch } from "react-redux";

/**
 *  Node Module Imports
 */
import clsx from "clsx";

/**
 *  Main Component
 */
const AppHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <AppBar color="inherit" className={clsx(classes.appBar, "app-bar")}>
        <Toolbar className={classes.appToolbar}>
          <Hidden lgUp>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => dispatch(toggleNavCollapsed())}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Hidden>

          <AppLogo />
          <Box className={classes.grow} />

          <Box className={classes.sectionDesktop}>
            <LanguageSwitcher />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default AppHeader;
