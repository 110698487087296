//  React Imports
import React from "react";

/**
 *  Material-UI Imports
 */
import List from "@material-ui/core/List";

/**
 *  Component Imports
 */
import VerticalCollapse from "./VerticalCollapse";
import VerticalItem from "./VerticalItem";
import VerticalNavGroup from "./VerticalNavGroup";

import routesConfig from "modules/routesConfig";

const Navigation = () => {
  return (
    <List>
      {routesConfig.map((item) => {
        return (
          <React.Fragment key={item.id}>
            {item.type === "group" && (
              <VerticalNavGroup item={item} level={0} />
            )}

            {item.type === "collapse" && (
              <VerticalCollapse item={item} level={0} />
            )}
            {item.type === "item" && <VerticalItem item={item} level={0} />}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default Navigation;
