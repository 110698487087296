import React from "react";
import ReactDOM from "react-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-notifications-component/dist/theme.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import 'react-table/react-table.css';

import "./shared/styles/index.css";
import "./assets/vendors/country-flag/sprite-flags-24x24.css";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn:
    "https://7b6e51cff9dc4b51be7d25cc3a71ad64@o402771.ingest.sentry.io/5654689",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
