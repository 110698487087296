//  React Imports
import React from "react";

/**
 *  Node Module
 */
import { Redirect } from "react-router-dom";

/**
 *  Utility Imports
 */
import { createRoutes } from "../@crema/utility/Utils";
import { initialUrl } from "../shared/constants/AppConst";

/**
 *  Route Imports
 */
import { errorPagesConfigs } from "./errorPages";
import { authRouteConfig } from "./auth";
import { managementConfig } from "./management";
import { dashboardConfig } from "./dashboard";
import { reportConfig } from "./report";

const routeConfigs = [
  ...errorPagesConfigs,
  ...dashboardConfig,
  ...authRouteConfig,
  ...managementConfig,
  ...reportConfig,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: "/",
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to="/error-pages/error-404" />,
  },
];

export default routes;
