//  React Imports
import React, { useContext } from "react";

/**
 *  Other Imports
 */
import { IntlProvider } from "react-intl";

/**
 *  Utility Imports
 */
import AppContext from "./AppContext";
import AppLocale from "shared/localization";
import PropTypes from "prop-types";

/**
 *  Main Component
 */
const LocaleProvider = (props) => {
  const { locale } = useContext(AppContext);
  const currentAppLocale = AppLocale[locale.locale];

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      {props.children}
    </IntlProvider>
  );
};

export default LocaleProvider;

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
