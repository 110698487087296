import React from "react";

export const reportRouteConfig = [
  {
    id: "report",
    title: "Report",
    messageId: "sidebar.report",
    type: "collapse",
    icon: "report",
    children: [
      {
        id: "report.demo",
        title: "Call Report",
        messageId: "sidebar.report.demo",
        type: "item",
        url: "/report/demo",
      },
    ],
  },
];

export const reportConfig = [
  {
    auth: ["user"],
    routes: [
      {
        path: "/report/demo",
        component: React.lazy(() => import("./Demo/")),
      },
    ],
  },
];
