export const initialUrl = "/dashboard/analytics"; // this url will open after login
export const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const authApi = {
  adminLogin: "/auth/admin/login",
  userSignup: "/users",
};

export const adminApi = {
  getWithPermissions: (start, limit) =>
    `/admin/all-with-permission?_sortBy=id&_order=DESC&_start=${start}&_limit=${limit}`,
  getAllWithPermissions:
    "/admin/all-with-permission?_start=0&_limit=10&_sortBy=id&_order=DESC",
  getById: (id) => `/admin/${id}`,
  create: "/admin",
  edit: (id) => `/admin/${id}`,
  delete: (id) => `/admin/${id}`,
  activate: (id) => `/admin/activate/${id}`,
  deactivate: (id) => `/admin/deactivate/${id}`,
  changePassword: (id) => `/admin/change-password/${id}`,
  getPermissions: (id) => `/admin/permissions/${id}`,
  getDashboardData: "/admin/dashboard",
  ldapAuth: "ldap-auth",
  textAlerts: (id) => `text-alerts/static/${id}`,
};

export const userApi = {
  get: (start, limit) => `/users?_start=${start}&_limit=${limit}`,
  getAll: "/users",
  getById: (id) => `/users/${id}`,
  create: "/users",
  createNotification: "/notifications/by-id",
  edit: (id) => `/users/${id}`,
  delete: (id) => `/users/${id}`,
  loginByOtp: "/auth/user/login/otp",
  verifyOtp: "/auth/user/verify-otp",
  resendOtp: "/auth/user/resend-otp",
};

export const notificationApi = {
  get: (start, limit) => `/notifications?_start=${start}&_limit=${limit}`,
  getAll: "/notifications",
  create: (recipientType) => {
    return recipientType === "arrayOfId"
      ? "/notifications/by-id"
      : `/notifications/${recipientType}`;
  },
  createById: "/notifications/by-id",
  getById: (id) => `/notifications/${id}`,
  getByGender: "/notifications/gender",
  getByDevice: "/notifications/device",
  getByReciepentNid: (nid) => `/notifications/recipient-nid?nid=${nid}`,
  markReadById: (id) => `/notifications/mark-one-as-read/${id}`,
  markAllReadByUserId: (id) =>
    `/notifications/mark-all-as-read-by-user-id/${id}`,
  markAllReadByNid: (nid) => `/notifications/mark-all-as-read-by-nid/${nid}`,
  getUnread: "/unread-notifications",

  // bulk
  getAllUsersForBulkNotification: "/notifications/user",
  getUsersForBulkNotification: (queryString) =>
    `/notifications/user?${queryString}`,
  postBulkNotification: (queryString) => `/notifications/user?${queryString}`,

  alertToAll: "/text-alerts/to-all",
};

export const reportApi = {
  get: (start, limit) => `/admin/report?_start=${start}&_limit=${limit}`,
  getAll: "/admin/report",
  getById: (id) => `/admin/report/${id}`,
  create: "/admin/report",
  export: "/admin/report/export",
};

// wip
export const textAlertApi = {
  getAll: "/text-alerts",
  get: (start, limit) => `/text-alerts/?_start=${start}&_limit=${limit}`,
  getById: (id) => `/text-alerts/${id}`,
  sendAll: "/text-alerts/to-all",
  sendById: (id) => `/text-alerts/to-one/${id}`,
  getStatic: "/text-alerts/static",
  updateStatic: "/text-alerts/static/1",
};
