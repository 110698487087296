import { GET_REPORTS_DATA } from "shared/constants/ActionTypes";
//   wip report
const initialState = {
  mainData: {
    data: [],
    // editing: false,
    pagination: {
      page: 0,
      rowsPerPage: 5,
      count: 0,
      lastPageFetched: 2,
    },
    // editingReport: null,
    // deleting: false,
    // deletingReport: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS_DATA:
      return {
        ...state,
        mainData: action.payload,
      };

    //   case ADD_ADMIN_MANAGEMENT_DATA:
    //     return {
    //       ...state,
    //       adminManagementData: {
    //         ...state.adminManagementData,
    //         data: [...state.adminManagementData.data, ...action.payload],
    //       },
    //     };

    //   case EDIT_ADMIN_INFORMATION_START:
    //     if (state.adminManagementData)
    //       return {
    //         ...state,
    //         adminManagementData: {
    //           ...state.adminManagementData,
    //           editing: true,
    //           editingUser: action.payload,
    //         },
    //       };
    //     else return state;
    //   case EDIT_ADMIN_INFORMATION_FINISH:
    //     if (state.adminManagementData)
    //       return {
    //         ...state,
    //         adminManagementData: {
    //           ...state.adminManagementData,
    //           editing: false,
    //           editingUser: null,
    //         },
    //       };
    //     else return state;
    //   case OPEN_ADMIN_DELETE_DIALOG:
    //     if (state.adminManagementData) {
    //       return {
    //         ...state,
    //         adminManagementData: {
    //           ...state.adminManagementData,
    //           deleting: true,
    //           deletingUser: action.payload,
    //         },
    //       };
    //     } else return state;
    //   case CLOSE_ADMIN_DELETE_DIALOG:
    //     if (state.adminManagementData) {
    //       return {
    //         ...state,
    //         adminManagementData: {
    //           ...state.adminManagementData,
    //           deleting: false,
    //           deletingUser: null,
    //         },
    //       };
    //     } else return state;

    default:
      return state;
  }
};
