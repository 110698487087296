//  React Imports
import React, { useContext, useEffect } from "react";

/**
 *  Redux Imports
 */
import { useDispatch, useSelector } from "react-redux";
import { setInitialPath } from "redux/actions";

/**
 *  Other Imports
 */
import { matchRoutes } from "react-router-config";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

/**
 *  Component Imports
 */
import { Loader } from "../index";

/**
 *  Utility Imports
 */
import AppContext from "./AppContext";
import { useAuthToken } from "./AppHooks";
import { initialUrl } from "shared/constants/AppConst";

/**
 *  Main Component
 */
const AuthRoutes = ({ children }) => {
  //  Data Store
  const initialPath = useSelector(({ settings }) => settings.initialPath);

  //  State Initializers
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { routes } = useContext(AppContext);
  const [user, loading] = useAuthToken();

  const currentRoute = matchRoutes(routes, pathname)[0].route;

  useEffect(() => {
    function setInitPath() {
      if (
        initialPath === "/" &&
        [
          "/signin",
          "/signup",
          "/confirm-signup",
          "/reset-password",
          "/forget-password",
        ].indexOf(pathname) === -1
      ) {
        dispatch(setInitialPath(pathname));
      }
    }

    setInitPath();
  }, [dispatch, initialPath, loading, pathname, user]);

  useEffect(() => {
    if (!loading) {
      if (!user && currentRoute.auth && currentRoute.auth.length >= 1) {
        history.push("/signin");
      } else if (
        (pathname === "/signin" ||
          pathname === "/signup" ||
          pathname === "/confirm-signup" ||
          pathname === "/reset-password" ||
          pathname === "/forget-password") &&
        user
      ) {
        if (pathname === "/") {
          history.push(initialUrl);
        } else if (
          initialPath !== "/" ||
          initialPath !== "/signin" ||
          initialPath !== "/signup"
        ) {
          history.push(initialPath);
        } else {
          history.push(initialUrl);
        }
      }
    }
  }, [user, loading, pathname, initialPath, currentRoute.auth, history]);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
