//  React Imports
import { useContext } from "react";

//  Material-UI Imports
import { makeStyles } from "@material-ui/core";

/**
 *  Utility Imports
 */
import AppContext from "@crema/utility/AppContext";
import { ThemeMode } from "shared/constants/AppEnums";

/**
 *  UseStyles
 */
const useStyles = makeStyles((theme) => {
  const { themeMode } = useContext(AppContext);
  return {
    container: {
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      position: "relative",
      top: 0,
      left: 0,
      width: "19rem",
      maxHeight: "100vh",
      [theme.breakpoints.up("lg")]: {
        position: "fixed",
      },
      [theme.breakpoints.up("xl")]: {
        width: "21.6rem",
      },
    },
    sidebarBg: {
      backgroundColor:
        themeMode === ThemeMode.SEMI_DARK
          ? theme.palette.sidebar.bgColor
          : themeMode === ThemeMode.LIGHT
          ? "white"
          : "#313541",
    },
    scrollAppSidebar: {
      paddingTop: 8,
      paddingBottom: 20,
      height: "calc(100vh - 58px) !important",

      [theme.breakpoints.up("xl")]: {
        height: "calc(100vh - 65px) !important",
      },
    },
    drawerScrollAppSidebar: {
      paddingTop: 8,
      paddingBottom: 20,
      height: "calc(100vh - 58px) !important",

      [theme.breakpoints.up("xl")]: {
        height: "calc(100vh - 65px) !important",
      },
    },
    sidebarStandard: {
      height: "100%",
      width: "100%",
      color: "white",
      overflow: "hidden",
    },
  };
});

export default useStyles;
