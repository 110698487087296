//  Action Imports
import { fetchError, fetchStart, fetchSuccess } from "../Common";

/**
 *  Type Imports
 */
import {
  GET_TEXT_ALERTS_DATA,
  VIEW_TEXT_ALERT_FINISH,
  VIEW_TEXT_ALERT_START,
  GET_STATIC_MSGS,
} from "shared/constants/ActionTypes";

/**
 *  Service Imports
 */
import ApiService from "@crema/services/ApiService";
import { textAlertApi } from "shared/constants/AppConst";

/**
 * TextAlert Actions
 */
export const onGetTextAlertsData = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(`${textAlertApi.getAll}`);

      const result = {
        data: data.data,
        viewing: false,
        selectedTextAlert: null,
        pagination: {
          start: 0,
          limit: 10,
          page: 0,
          count: data.total, //total
        },
      };

      dispatch({
        type: GET_TEXT_ALERTS_DATA,
        payload: result,
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onGetStaticMsgs = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(textAlertApi.getStatic);

      dispatch({
        type: GET_STATIC_MSGS,
        payload: data[0],
      });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const onViewTextAlertStart = (TextAlert) => ({
  type: VIEW_TEXT_ALERT_START,
  payload: TextAlert,
});

export const onViewTextAlertFinish = () => ({
  type: VIEW_TEXT_ALERT_FINISH,
});

export const setTextAlertCurrentTablePage = (page, limit) => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());

      const start = page * limit;

      let data = await ApiService.get(`${textAlertApi.get(start, limit)}`);

      const result = {
        data: data.data,
        viewing: false,
        selectedTextAlert: null,
        pagination: {
          start,
          page,
          limit,
          count: data.total, //total
        },
      };

      dispatch({
        type: GET_TEXT_ALERTS_DATA,
        payload: result,
      });
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const setTextAlertRowsPerPage = (limit) => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());

      let data = await ApiService.get(`${textAlertApi.get(0, limit)}`);

      const result = {
        data: data.data,
        viewing: false,
        selectedTextAlert: null,
        pagination: {
          start: 0,
          page: 0,
          limit: limit,
          count: data.total, //total
        },
      };

      dispatch({
        type: GET_TEXT_ALERTS_DATA,
        payload: result,
      });
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
