import axios from "axios";
import { baseApiUrl } from "shared/constants/AppConst";
import { authHeader } from "@crema/utility/Utils";

const Api = axios.create({
  baseURL: baseApiUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    ...authHeader(),
  },
});

export const setAuthToken = (token) => {
  if (token) {
    Api.defaults.headers.common["Authorization"] = "Bearer " + token;
    localStorage.setItem("token", token);
    console.log(token);
  } else {
    delete Api.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
  }
};

export default Api;
