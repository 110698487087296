//  React Imports
import React from "react";

/**
 *  Material-UI Imports
 */
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 *  Asset Imports
 */
import { ReactComponent as LogoIcon } from "assets/images/SMOI.svg";

// UseStyles
const useStyles = makeStyles(() => ({
  logoRoot: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
  },
  logo: {
    height: 36,
    marginRight: 10,
  },
}));

/**
 *  Main Component
 */
const AppLogo = () => {
  const classes = useStyles();
  return (
    <Box className={classes.logoRoot}>
      <LogoIcon className={classes.logo} />
    </Box>
  );
};

export default AppLogo;
