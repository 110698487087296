import { dashboardRouteConfig } from "./dashboard";
import { managementRouteConfig } from "./management";
import { reportRouteConfig } from "./report";

// ......
const routesConfig = [
  ...dashboardRouteConfig,
  ...reportRouteConfig,
  ...managementRouteConfig,
];

export default routesConfig;
