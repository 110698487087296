import React from "react";
// import { store } from "redux/store";

// issue
// const store = configureStorr();
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
// const state = store.getState();
// let user = state ? state.auth.user : {};

// testing
let user = userInfo;
// permissions from localStorage

// console.log("user", user?.notificationCreate);
// const {
//   adminCreate,
//   adminDelete,
//   adminFind,
//   adminUpdate,

//   notificationCreate,
//   notificationDelete,fd
//   notificationFind,
//   notificationUpdate,

//   userCreate,
//   userDelete,
//   userFind,
//   userUpdate,
// } = user
//   ? user
//   : {
//       // adminCreate: true,
//       adminCreate: false,
//       adminDelete: false,
//       adminFind: false,
//       adminUpdate: false,
//       // notificationCreate: true,
//       notificationCreate: false,
//       notificationDelete: false,
//       notificationFind: false,
//       notificationUpdate: false,
//       // userCreate: true,
//       userCreate: false,
//       userDelete: false,
//       userFind: false,
//       userUpdate: false,
//     };

// console.log(notificationCreate);

export const managementRouteConfig = [
  {
    id: "management",
    title: "Management",
    messageId: "sidebar.management",
    type: "collapse",
    icon: "edit",
    children: [
      // (adminCreate || adminDelete || adminFind || adminUpdate) &&
      {
        id: "management.admin",
        title: "Admin",
        messageId: "sidebar.management.admin",
        type: "item",
        url: "/manage/admin",
      },
      // (userCreate || userDelete || userFind || userUpdate) &&
      {
        id: "management.user",
        title: "User",
        messageId: "sidebar.management.user",
        type: "item",
        url: "/manage/user",
      },

      // (notificationCreate ||
      //   notificationDelete ||
      //   notificationFind ||
      //   notificationUpdate) &&
      {
        id: "management.notification",
        title: "Notification",
        messageId: "sidebar.management.notification",
        type: "item",
        url: "/manage/notification",
      },

      {
        id: "management.emergencyAlert",
        title: "Emergency Alert",
        messageId: "sidebar.management.emergencyAlert",
        type: "item",
        url: "/manage/emergencyAlert",
      },

      {
        id: "management.bulk-notification",
        title: "Bulk Notification",
        messageId: "sidebar.management.bulkNotification",
        type: "item",
        url: "/manage/bulk-notification",
      },
      // wip
      // {
      //   id: "management.text-alerts",
      //   title: "Text Alerts",
      //   messageId: "sidebar.management.textAlerts",
      //   type: "item",
      //   url: "/manage/text-alerts",
      // },
    ],
  },
];

export const managementConfig = [
  {
    auth: ["user"],
    routes: [
      {
        path: "/manage/admin",
        component: React.lazy(() => import("./Admin")),
      },
      {
        path: "/manage/user",
        component: React.lazy(() => import("./User")),
      },
      {
        path: "/manage/emergencyAlert",
        component: React.lazy(() => import("./EmergencyAlert")),
      },
      {
        path: "/manage/notification",
        component: React.lazy(() => import("./Notification")),
      },
      {
        path: "/manage/bulk-notification",
        component: React.lazy(() => import("./BulkNotification")),
      },
      {
        path: "/manage/text-alerts",
        component: React.lazy(() => import("./TextAlerts")),
      },
    ],
  },
];
