//  React Imports
import { useEffect, useState } from "react";

/**
 *  Redux Imports
 */
import { useDispatch, useSelector } from "react-redux";

/**
 *  Redux Imports
 */
import { setJWTToken } from "redux/actions";

/**
 *  Utility Imports
 */
import { UPDATE_AUTH_USER } from "shared/constants/ActionTypes";

/**
 *  Hook
 */
export const useAuthToken = () => {
  //  Store Selectors
  const { user } = useSelector(({ auth }) => auth);

  //  State Initializers
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userAthCheck = async () => {
      try {
        const token = localStorage.getItem("token");
        let userInfo = localStorage.getItem("userInfo");
        userInfo = JSON.parse(userInfo);

        dispatch(setJWTToken(token));
        // const res = await jwtAxios.get("/auth");

        if (token)
          dispatch({
            type: UPDATE_AUTH_USER,
            payload: userInfo,
          });
      } catch (e) {
        dispatch(setJWTToken(null));
        return null;
      }
    };

    const checkAuth = () => {
      Promise.all([userAthCheck()]).then(() => {
        setLoading(false);
      });
    };
    checkAuth();
  }, [dispatch]);

  return [user, loading];
};

export const useAuthUser = () => {
  const authUser = useSelector(({ auth }) => auth.user);
  if (authUser) {
    return authUser;
  }
  return {
    displayName: "Loading",
  };
};
